export const questions1 = [
    {label:'Представь, что ты стал правителем королевства. Налаженные в прошлом процессы работают хорошо. Однако, как правитель, у тебя есть возможность внести изменения в уже существующие законы и порядки.',
        answer1:'Оставлю всё так, как есть, доверяя мудрости предыдущего короля', 
        answer2: 'Изменю законы, так как всегда есть то, что можно улучшить',
        image:'q1.jpeg'},
    {label:'Ты крестьянин, и по традиции твоя семья всегда занималась выращиванием брюквы. Однако в последнее время задача сбора урожая стала скучной рутиной, которую хотелось бы разнообразить. Каким будет твой следующий шаг?',
        answer1:'Продолжу традицию своей семьи несмотря на то, что работа с брюквой уже надоела', 
        answer2: 'Чувствуя потребность в перемене, решу заняться чем-то новым и отказаться от выращивания брюквы. Возможно, сменю профессию и стану бродячим артистом',
        image:'q2.jpeg'},
    {label:'Твой хороший знакомый предлагает отправить караван с новым товаром, который может принести большую прибыль. Какое решение ты примешь?',
        answer1:'Я останусь при своих деньгах и буду торговать старым товаром. Надёжнее иметь «синицу в руках», чем рисковать и терять всё', 
        answer2: ' Попробую что-то новое, ведь «журавль в небе» может принести ещё большую выгоду, чем то, что уже есть',
        image:'q3.jpeg'},
    {label:'Приближается день урожая. Ты вызвался помогать в организации празднования. Что предпримешь?',
        answer1:'Проведу праздник по местным обычаям, чтобы не разочаровать жителей', 
        answer2: 'Изменю программу праздника, чтобы сделать его более ярким и запоминающимся для жителей',
        image:'q4.jpeg'},
];

export const questions2 = [
    {label:'Ты староста деревушки, которая процветает благодаря ремёслам. Однако у вас нет кузнеца — необходимого человека для обработки металла и изготовления оружия. Ты задумался о том, как решить эту проблему. Вариантов несколько:',
        answer1:'Начну поиск кузнеца за пределами деревни. Возможно, найду подходящего в соседних поселениях или городах. Это потребует времени и ресурсов, но это может быть самым быстрым и эффективным решением проблемы', 
        answer2:'Найду молодого и талантливого человека в своей деревне, который заинтересуется профессией кузнеца, обеспечу ему необходимое обучение и инструменты для работы',
        image:'q5.jpeg'},
    {label:'Настал момент, когда тебе предстоит выбрать вторую половинку. Как организуешь поиски?',
        answer1:'Найти вторую половинку мне поможет судьба, нужно срочно отправиться в столицу', 
        answer2:'Моя любовь точно находится среди тех людей, которых я знаю. Начну с общения со своими знакомыми и укреплю имеющиеся связи',
        image:'q6.jpeg'},
    {label:'Ты знаешь, что кто-то обсуждает тебя за спиной. Слухи могут быть опасными и нанести серьёзный ущерб. В данной ситуации ты стоишь перед трудным выбором, который может повлиять на отношения и общее благополучие. Что сделаешь?',
        answer1:'Хочу услышать правду, несмотря на возможное разрушение отношений', 
        answer2:'Останусь в неведении, чтобы сохранить отношения',
        image:'q7.jpeg'},
    {label:'Твой добрый друг поделился историей о своём великом походе и незабываемой ловле рыбы. Ты можешь отреагировать на неё по-разному:',
        answer1:'Сделаю вид, что поверю в его слова. Позже попытаюсь узнать, водится ли в том озере такая рыба. Но если доказательств не найду, решу, что это была всего лишь шутка', 
        answer2:'Повеселюсь вместе со своим другом, даже если он слегка преувеличил',
        image:'q8.jpeg'},                    
];

export const questions3 = [
    {label:'Когда ты возделывал поле, плуг зацепился за крышку сундука. Ты обнаруживаешь гору золота, которую никогда раньше не видел. Что сделаешь с найденным богатством?', 
        answer1:'Отдам деньги общине, чтобы обновить амбар и нанять учителей для деревенских детей', 
        answer2:'Сразу начну планировать путешествие с посещением лавовых водопадов королевства Гномов и храма богини Земли', 
        answer3:'Я использую эти богатства, чтобы нанять отряд воинов для защиты моей деревни от барона, который обижает крестьян', 
        answer4:'Устрою настоящий праздник для всей деревни с вкусными яствами, песнями и танцами',
        image:'l1.jpeg'},
    {label:'Ты оказался в деревне, где местные жители страдают от загадочной болезни. Что ты предпримешь?', 
        answer1:'Предложу наставления и древние рецепты лечения, основанные на опыте предков', 
        answer2:'Опрошу местных жителей, выясню особенности болезни и приступлю к исследованию причин', 
        answer3:'Куплю у заезжего торговца зелье лечения из дальних стран в надежде, что оно будет более эффективным, чем обычные методы', 
        answer4:'Ободрю страждущих, подниму дух, окружу вниманием, пока жители исцеляются',
        image:'l1.jpeg'},
    {label:'Ты готовишься к экзамену на вступление в школу волшебников. Критерии отбора секретны, поэтому поступающие сами вольны решать, как готовиться к испытанию. Твои действия:', 
        answer1:'Куплю учебники по основам магии. Пусть мои знания не будут глубокими, но я твёрдо усвою основы', 
        answer2:'Закроюсь в библиотеке для того, чтобы в древних фолиантах найти забытое заклинание — это должно впечатлить экзаменаторов', 
        answer3:'Попытаюсь создать новое заклинание. Может оно и не будет эффективным, но это покажет, что я могу творить и создавать что-то новое', 
        answer4:'Буду готовиться с друзьями, если станем волшебниками, то только вместе!',
        image:'l1.jpeg'},
    {label:'Во время проведения празднования совершеннолетия принято выбирать себе тотемное животное-покровителя. Кого ты предпочтешь?', 
        answer1:'Кот — покровитель дома и размеренной жизни', 
        answer2:'Ворон — помощник в поиске потерянного', 
        answer3:'Волк — верный компаньон скитальцев и бунтарей', 
        answer4:'Пёс — друг и охранник',
        image:'l1.jpeg'},
    {label:'Ты решил порадовать свою вторую половинку и отправился за подарком на ярмарку. Какой подарок выберешь?', 
        answer1:'Что-нибудь из украшений — это универсальный подарок. Постараюсь найти не очень дорогое и не слишком дешёвое', 
        answer2:'Лучший подарок — это книга. Редкая и магическая. Интересно, что она в себе скрывает?', 
        answer3:'Изысканный кинжал — точно пригодится в путешествиях', 
        answer4:'Ящик заморского вина — можно будет пригасить друзей и закатить пирушку',
        image:'l1.jpeg'},        
/*

    {label:'', 
        answer1:'', 
        answer2:'', 
        answer3:'', 
        answer4:'',
        image:'l1.jpeg'},

*/

];

export const square1= [
     {label:'Тебе предоставляется возможность освободить народ от мрака и тирании злого короля. Какой путь изменений выберешь?', 
        answer1:'Брошу вызов королю на рыцарском турнире и сражусь с ним один на один', 
        answer2:'Организую повстанцев, соберу армию и свергну короля силой', 
        answer3:'Изучу слабые места короля, его привычки и слуг, чтобы организовать дворцовый переворот', 
        image:'l1.jpeg'},
    {label:'Ты стал свидетелем конфликта двух древних родов. Уже льётся кровь и страдают в том числе и невинные люди.', 
        answer1:'Не время для раздумий — пока я медлю, страдают невинные. Послушаю своё сердце, выберу одну из сторон и помогу ей победить в этом противостоянии', 
        answer2:'Главная причина этого конфликта — неравенство. Из-за того, что горстка аристократов считает себя выше остальных, страдают все, и даже если этот конфликт будет решён, то причина зла останется. Я построю свой мир, где люди будут равны друг другу', 
        answer3:'Насилие — не решение проблем. Найду вариант, который устроит всех, возможно, это будут мирные переговоры или династический брак', 
        image:'l1.jpeg'},
    {label:'Во время твоего путешествия в одном из городов случилось опустошительное землетрясение. По словам местных подземные толчки в последнее время стали случаться чаще и сильнее. Что ты предпримешь?', 
        answer1:'Помогу жителям отстроить город, прослежу, чтобы дома были максимально крепкими и устойчивыми к повторным землетрясениям', 
        answer2:'Соберу выживших и помогу им добраться до мест, где землетрясений не бывает и помогу обустроить свой быт', 
        answer3:'Эти землетрясения слишком загадочны. Проведу расследование — не является ли причиной катаклизма чья-то тёмная магия', 
        image:'l1.jpeg'},
    {label:'Ты находишь артефакт необычайной магической силы, способной полностью изменить мир. Твои действия:', 
        answer1:'Если этот артефакт попадёт не в те руки, то зло станет невозможно остановить. Его нужно немедленно уничтожить', 
        answer2:'Это возможность изменить наш мир к лучшему. Немедленно воспользуюсь артефактом', 
        answer3:'Исследую артефакт для того, чтобы найти способ укротить его силы', 
        image:'l1.jpeg'},
    {label:'Ты обращаешь внимание на то, что твой оруженосец пуглив и боится опасностей. Как поступишь?', 
        answer1:'Расскажу ему несколько историй и вдохновлю своим опытом', 
        answer2:'Возьму его в бой. В строю, окружённый товарищами, он преодолеет свои страхи', 
        answer3:'Подстрою ситуацию, где он окажется вынужден столкнуться со страхом лицом к лицу. Моя задача сделать ситуацию контролируемой, а уровень страха не очень сильным', 
        image:'l1.jpeg'},                                
]

export const square2= [
    {label:'Твоё королевство оказывается под угрозой внешней агрессии. Какой из следующих вариантов деятельности ты предпочтёшь?', 
    answer1:'Приступлю к строительству крепостей и укреплений для защиты', 
    answer2:'Усовершенствую оружие и обучу войска для готовности к атаке', 
    answer3:'Найду союзников и проведу переговоры для обеспечения мира',     
    image:'l1.jpeg'},
    {label:'В твоей деревне возникла проблема с недостатком пищи. Что ты предпримешь, чтобы решить эту проблему?', 
    answer1:'Отправлю своих людей на охоту и рыбалку для обеспечения продовольствия', 
    answer2:'Начну собирать зерно и организую его хранение для более эффективного использования', 
    answer3:'Организую сбор пищи и попрошу поддержку у соседних деревень и феодалов',     
    image:'l1.jpeg'},
    {label:'Ты как лидер в королевстве имеешь множество обязанностей. Как бы ты предпочёл управлять своими подчинёнными?', 
    answer1:'Строгими правилами и мудрыми законами, которым они должны следовать', 
    answer2:'Наставлениями и примерами, чтобы они могли повторять за тобой', 
    answer3:'Заботой и поддержкой, чтобы они видели твоё внимание и могли доверять тебе',     
    image:'l1.jpeg'},
    {label:'Твоему королевству угрожает восстание. Как ты восстановишь порядок?', 
    answer1:'Незамедлительно отправлю войска для подавления бунта силой. Чем раньше это произойдёт, тем меньше людей пострадает', 
    answer2:'Соберу совет из уважаемых жителей королевства и проведу переговоры для разрешения конфликта', 
    answer3:'Расследую причины возникновения восстания и предприму меры для улучшения жизни общества',     
    image:'l1.jpeg'},
    {label:'Тебе необходимо научить жителей королевства приручать и дрессировать магических животных для помощи в хозяйстве. Как ты организуешь обучение?', 
    answer1:'Открою школы и академии дрессировки для массового обучения ', 
    answer2:'Организую серию практических занятий по приручению магических животных. Практика — лучший способ обучения', 
    answer3:'Окажу индивидуальную поддержку и наставничество, чтобы жители учились самостоятельно',     
    image:'l1.jpeg'},                
]

export const square3= [
    {label:'Ты отправился в очередное путешествие и столкнулся с опасным и загадочным лесом. Что будешь делать?', 
    answer1:'Вернусь назад и попытаюсь найти другой путь', 
    answer2:'Постараюсь изучить лес и найти выход из ситуации', 
    answer3:'Продолжу путешествие через лес и постараюсь найти что-то интересное',     
    image:'l1.jpeg'},
    {label:'В ходе своего путешествия ты случайно встречаешь магический артефакт, о котором слышал только в легендах. Какое решение примешь?', 
    answer1:'Оставлю его в покое.  Кто знает, что он таит?', 
    answer2:'Попытаюсь вспомнить легенды и слухи о нём. Возможно, они расскажут, как им пользоваться', 
    answer3:'Исследую его и попытаюсь понять, на что он способен',     
    image:'l1.jpeg'},
    {label:'Ты попал в плен к злобному колдуну, который хочет использовать тебя в коварных планах. Как попытаешься выбраться?', 
    answer1:'Постараюсь убедить колдуна отпустить меня, обещая не причинять ему вреда', 
    answer2:'Узнаю побольше о его планах, чтобы найти в них несостыковку и устрою побег', 
    answer3:'Постараюсь вырваться силой как можно быстрее — каждая минута с ним — это опасность',     
    image:'l1.jpeg'},
    {label:'Тебе предлагают выбрать, какое заклинание хочешь изучить:', 
    answer1:'Начну с чего-то простого и надёжного', 
    answer2:'Возьмусь за сложное, запутанное и невероятно сильное', 
    answer3:'Выберу полезное для моих приключений',     
    image:'l1.jpeg'},
    {label:'Ты встретил гадалку, которая очень много знает о тебе. Она предлагает предсказать будущее и, возможно, твою смерть. Что ты сделаешь?', 
    answer1:'Откажусь, мне не нужно такое знание', 
    answer2:'Попытаюсь понять, как она это делает', 
    answer3:'Соглашусь — пусть будущее пугает, но это поможет мне',     
    image:'l1.jpeg'},
    {label:'Твой король решает ввести новые законы, которые повышают будущие налоги в два раза. Как ты отнесёшься к изменению?', 
    answer1:'Закон есть закон. Я знаю, что ситуация тяжёлая и, возможно, — это единственный выход', 
    answer2:'Попытаюсь донести до короля, что нужно не увеличивать налоги, а уменьшать, так как это приведёт к росту торговли и ремёсел, и через некоторое время в казну будет поступать больше денег', 
    answer3:'Я попробую найти новые способы пополнения казны',         
    image:'l1.jpeg'},    
]

export const square4= [
    {label:'Ты решил сделать театральную постановку о рыцарях. О чём будет твоё выступление?', 
    answer1:'О подвигах и приключениях', 
    answer2:'О забавных происшествиях', 
    answer3:'О даме сердца',     
    image:'l1.jpeg'},
    {label:'Ты отправляешься в путешествие и планируешь маршрут. Что бы ты учёл в первую очередь?', 
    answer1:'Безопасность маршрута и доступность таверн', 
    answer2:'Возможность посетить интересные места из легенд и сказок', 
    answer3:'Живописные виды по пути',     
    image:'l1.jpeg'},
    {label:'Между твоими друзьями возник спор, который ты хочешь решить. Как ты поступишь?', 
    answer1:'Предложу высказаться каждой стороне, а сам побуду посредником и судьёй', 
    answer2:'Сведу предмет спора к шутке. После этого друзьям будут неловко спорить из-за этого', 
    answer3:'Произнесу речь о ценности дружбы и предложу забыть о конфликте и пожать друг другу руки',     
    image:'l1.jpeg'},
    {label:'Ты решил организовать праздник и хочешь завлечь на него жителей соседних деревень. Как ты поступишь?', 
    answer1:'Подготовлю богатое угощение', 
    answer2:'Приглашу на праздник бродячий цирк, чтобы он развлёк гостей', 
    answer3:'Организую конкурс талантов, чтобы каждый смог проявить себя',     
    image:'l1.jpeg'},
    {label:'Твой друг расстался с девушкой и очень страдает из-за этого. Как бы поступить?', 
    answer1:'Попытаюсь успокоить его, утешив, что других девушек вокруг много', 
    answer2:'Расскажу ему анекдот и развеселю', 
    answer3:'Предложу выразить свою грусть в стихах и отправить их своей даме сердца',     
    image:'l1.jpeg'},
]