import { useState } from 'react';
import '../App.css';


function SurveyOption (props) 
{    
    function selectOption(){
        props.setAnswered(true);
        props.setAnswer(props.question.qNumber);
    }
    let value = props.question.value;
    let label = props.question.label;


    
    
    if(props.isAnswered)
    {
        return(       
            <p><input  className="w-4 h-4 bg-gray-900 border-gray-900 focus:ring-red-950 accent-slate-950" name="question" type="radio" value={value} id={value} onChange={selectOption} /><label for={value}> {label}</label></p>
        );
    }
    else
    {
        return(        
            <p><input className="w-4 h-4 text-stone-600 bg-gray-100 border-gray-300 focus:ring-gray-500 dark:focus:ring-gray-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"  name="question" type="radio" value={value} id={value} onChange={selectOption} checked={false}/><label for={value}> {label}</label></p>
        );
    }
    
}
export default SurveyOption