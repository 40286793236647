import { useState } from 'react';
import '../App.css';
import axios from 'axios';



export default function SubmitButton (props)  {
    function appendFileOnDrive(guid, message){
      
    }
    function handleClick() {        
        props.setIndex(props.index+1);
        props.setAnswered(false);

        
        if(props.mode=='axe1')
        {
          if(props.currAnswer==2){
            let newChange = props.result.change+1;
            props.setResult({...props.result, change:newChange })
            appendFileOnDrive('Вопрос: '+props.uid, props.currentQuestion.label+' Ответ: '+ props.currentQuestion.answer2);
          }
          else{
            let newStability = props.result.stability+1;
            props.setResult({...props.result, stability: newStability})
            appendFileOnDrive(props.uid, 'Вопрос: '+props.uid, props.currentQuestion.label+' Ответ: '+ props.currentQuestion.answer1);
          }
        }
        else if(props.mode=='axe2')
        {
          if(props.currAnswer==1){
            appendFileOnDrive(props.uid, 'Вопрос: '+props.uid, props.currentQuestion.label+' Ответ: '+ props.currentQuestion.answer1);
            let newSearch = props.result.search+1;
            props.setResult({...props.result, search:newSearch })

          }
          else{
            let newCommunity = props.result.community+1;
            props.setResult({...props.result, community: newCommunity})
            appendFileOnDrive(props.uid, 'Вопрос: '+props.uid, props.currentQuestion.label+' Ответ: '+ props.currentQuestion.answer2);
          }
        }
        else if(props.mode=='final')
        {
          if(props.currAnswer==1){
            let newStability = props.result.stability+1;
            props.setResult({...props.result, stability: newStability})
            appendFileOnDrive(props.uid, 'Вопрос: '+props.uid, props.currentQuestion.label+' Ответ: '+ props.currentQuestion.answer1);

          }
          else if(props.currAnswer==2){
            let newSearch = props.result.search+1;
            props.setResult({...props.result, search:newSearch })
            appendFileOnDrive(props.uid, 'Вопрос: '+props.uid, props.currentQuestion.label+' Ответ: '+ props.currentQuestion.answer2);
          }
          else if(props.currAnswer==3){
            let newChange = props.result.change+1;
            props.setResult({...props.result, change:newChange })
            appendFileOnDrive(props.uid, 'Вопрос: '+props.uid, props.currentQuestion.label+' Ответ: '+ props.currentQuestion.answer3);
          }
          else if(props.currAnswer==4){
            let newCommunity = props.result.community+1;
            props.setResult({...props.result, community: newCommunity})
            appendFileOnDrive(props.uid, 'Вопрос: '+props.uid, props.currentQuestion.label+' Ответ: '+ props.currentQuestion.answer4);
          }
        }
        else if(props.mode=='path'){
          if(props.currAnswer==1){
            let newvalue = props.result.option1+1;
            props.setResult({...props.result, option1: newvalue})

          }
          else if(props.currAnswer==2){
            let newvalue = props.result.option2+1;
            props.setResult({...props.result, option2: newvalue})
          }
          else if(props.currAnswer==3){
            let newvalue = props.result.option3+1;
            props.setResult({...props.result, option3: newvalue})
          }
        }


      }
      

      return (
        <div className='text-center'>
          <img className='imgButton' onClick={handleClick}  src='../image/buttons/response.png'></img>        
        </div>
      );
}