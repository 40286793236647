import './App.css';
import SubmitButton from './components/submit'
import SurveyOption from './components/option'
import {questions1,questions2, questions3, square1, square2,square3,square4} from './data/data'
import { useEffect, useState } from 'react';
import axios from 'axios';
 

function App() {
  function refreshPage() {
    window.location.reload(false);
  }

  function uuidv4() {
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
  }

  function createFileOnDrive(guid,message){

  }

  function appendFileOnDrive(guid, message){
    

  }
  
  const queryString = window.location.search; 
  const { pathname } = window.location;
  console.log(pathname);


  let path;
  if (queryString =='?route=1' || pathname=='/route1') path=1;
  if (queryString =='?route=2' || pathname=='/route2') path=2;
  if (queryString =='?route=3' || pathname=='/route3') path=3;
  if (queryString =='?route=4' || pathname=='/route4') path=4;

  //const params = new URLSearchParams(window.location.pathname);
  //const path = params.get('path');

  const numberOfQuestionInBlock=3;  
  const [index, setIndex] = useState(0);
  const [uid, setUID] = useState(uuidv4());
  const [resultText, setResultText] = useState('');
  const [resultItem, setResultItem] = useState('');
  const [userCode, setUserCode] = useState('');
  const [mode, setMode] = useState('axe1'); //axe2, path, final, result
  const [isAnswered, setAnswered] = useState(false)
  const [currAnswer, setAnswer] =useState(0);
  const [result, setResult] = useState({stability: 0, change:0, search:0, community:0, option1: 0, option2:0, option3:0});
  const [showElement,setShowElement] = useState(true)

  

  let arrayOfQuestions;


  if(path==1){
    const shuffled = square1.sort(() => 0.5 - Math.random());
    arrayOfQuestions = shuffled.slice(0, 5);
   
  }
  if(path==2){
    const shuffled = square2.sort(() => 0.5 - Math.random());
    arrayOfQuestions = shuffled.slice(0, 5);


  }
  if(path==3){
    const shuffled = square3.sort(() => 0.5 - Math.random());
    arrayOfQuestions = shuffled.slice(0, 5);


  }
  if(path==4){
    const shuffled = square4.sort(() => 0.5 - Math.random());
    arrayOfQuestions = shuffled.slice(0, 5);



  }
  if(path!=1 && path!=2 && path!=3 && path!=4){
    const shuffled1 = questions1.sort(() => 0.5 - Math.random());
    const shuffled2 = questions2.sort(() => 0.5 - Math.random());
    const shuffled3 = questions3.sort(() => 0.5 - Math.random());
  
    
    let selected1 = shuffled1.slice(0, numberOfQuestionInBlock);
    let selected2 = shuffled2.slice(0, numberOfQuestionInBlock);
    let selected3 = shuffled3.slice(0, numberOfQuestionInBlock+1);
    arrayOfQuestions  = [...selected1, ...selected2, ...selected3];

  }


  const [answerArr, setAnswerArr] =useState(arrayOfQuestions);
  
  useEffect(()=>{
    if(!showElement){
    setTimeout(function() {
      setShowElement(true)
         }, 15000);}
       },
   [showElement]);




  useEffect(()=>{
    if(path!=1 && path!=2 && path!=3 && path!=4)
    {
      if(index == 0) createFileOnDrive(uid,'---')
      //else appendFileOnDrive(uid,answerArr[index].label)
    }
  },[index]);
  
  
  useEffect (()=>{    
    var isResult=false;
    if(path==1 || path==2 || path==3 || path==4 ){
      setMode('path');
      if(result.option1>=2){
        isResult=true;
        if(path==1) {
          setResultText('Щит');
          setResultItem('00.jpg');
        } //-Рыцарь
        else if (path ==2) {
          setResultText('Большая фляга'); //-Трактирщик
          setResultItem('01.jpg');
        }
        else if (path ==3) {
          setResultText('Котелок'); //-Оруженосец
          setResultItem('02.jpg');
        }
        else if (path ==4) {
          setResultText('Торба'); //-Торговец
          setResultItem('03.jpg');
        }
      }
      else if (result.option2>=2){
        isResult=true;
        if(path==1) {
          setResultText('Сломанные кандалы'); //-Разбойник
          setResultItem('04.jpg');
        }
        else if (path ==2) {
          setResultText('Слиток серебра'); //-Ювелир
          setResultItem('05.jpg');
        }
        else if (path ==3) {
          setResultText('Балахон'); //Кузнец? Мудрец?
          setResultItem('06.jpg');
        }
        else if (path ==4) {
          setResultText('Яркие одежды');  //-Бард
          setResultItem('07.jpg');
        }
      }
      else if(result.option3>=2){
        isResult=true;
        if(path==1) {
          setResultText('Шляпа'); //-Маг
          setResultItem('08.jpg');
        }
        else if (path ==2) {
          setResultText('Шляпа с пером'); //-Дворянин
          setResultItem('09.jpg');
        }
        else if (path ==3) 
        {
          setResultText('Походный посох'); //-Следопыт
          setResultItem('10.jpg');
        }
        else if (path ==4) {
          setResultText('Перо');     //-Поэт
          setResultItem('11.jpg');
        }
      }

      if(isResult){
        setMode('result');   
        setShowElement(false);
        const uc=generateRandomCode();
        const testEncodedResult= `${path}${result.option1}${result.option2}${result.option3}`;;
        setUserCode(testEncodedResult);
  
        //const url = 'https://n8n.besoon.ru/webhook-test/32ec9c94-b732-4983-85b3-64ff66b6b911';
        const url =   'https://n8n.besoon.ru/webhook/32ec9c94-b732-4983-85b3-64ff66b6b911';
        const data = {
          code: uc,
          square: path,
          a1: result.option1,
          a2: result.option2,
          a3: result.option3                                    
        };
      
        fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })
          .then(response => {
            if (response.ok) {
              return response.json();
            }
          })
          .catch(error => {
            console.error(error);
            // Обработайте ошибку здесь
          });

      }


    }else{
      if(index==numberOfQuestionInBlock)setMode('axe2');
      if(index>=numberOfQuestionInBlock*2)
      {
        let resultArray=[result.stability, result.change, result.search, result.community];
        resultArray.sort();
        if(resultArray[2]==resultArray[3]) 
        {
          setMode('final');
        }    
        else {
          resultArray=[result.stability, result.change, result.search, result.community];
          let maxIndex=0;
          for (var i = 0; i < resultArray.length; i++) {
            if (resultArray[maxIndex] < resultArray[i]) {
                maxIndex = i;
            }        
          }
          switch (maxIndex) {
            case 0:
              setResultText('Зеленый изумруд'); // Стабильность
              setResultItem('green.jpg');
              break;
            case 1:
              setResultText('Красный рубин'); //Изменения
              setResultItem('red.jpg');
              break;
            case 2:
              setResultText('Голубой топаз'); //Поиск
              setResultItem('blue.jpg');
              break;            
            case 3:
              setResultText('Желтый сапфир'); //Принадлежность
              setResultItem('yellow.jpg');
              break;            
          }
          
          const uc=generateRandomCode();
          const testEncodedResult= `${result.search}${result.change}${result.stability}${result.community}`;;

          setUserCode(testEncodedResult);


          setMode('result');      
          setShowElement(false);

          const url = 'https://n8n.besoon.ru/webhook/32ec9c94-b732-4983-85b3-64ff66b6b9be';
          const data = {
            code: uc,
            search: result.search,
            changes: result.change,
            stability: result.stability,
            community: result.community                                    
          };
        
          fetch(url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          })
            .then(response => {
              if (response.ok) {
                return response.json();
              }
            })
            .catch(error => {
              console.error(error);
              // Обработайте ошибку здесь
            });

  
        }
      }
    }


  },[result, index]);


  let currentQuestion = answerArr[index];

  if (mode=='result'){
    return( 
      <figure className="md:flex p-8 md:p-0 dark:text-stone-950 text-3xl bg-slate-50 bg-opacity-50 rounded-3xl md:text-center">  
        <div className="pt-6 md:p-8 md:text-center space-y-4 font-sans">               
        <div className='md:text-center text-center font-sans text-3xl'>
            Ваш код теста: {userCode}<br /> <br />
            Результат:  <br />  
          </div>            
          {resultItem.length>0?<img src={`../image/itmes/${resultItem}`} alt={resultText}></img>:<></>}
          <br />           

          {showElement?<div className='text-center'><img className='imgButton' onClick={refreshPage}  src='../image/buttons/newAttempt.png'></img></div>:<></>}
          
        </div>   
      </figure>
      );
  }
  else if(answerArr.length>index && (mode=='axe1'||mode=='axe2'))
  {
    return (
      <>
      <br/>
      <figure className="md:flex p-8 md:p-0   dark:text-stone-950  bg-slate-50 bg-opacity-50 rounded-3xl">  
        <div className="pt-6 md:p-8 md:text-left space-y-4 font-sans">               

          <div className='text-xl font-medium '><b>{currentQuestion.label}</b></div>
          <div className='text-center font-sans text-xl'>
            <br />
          </div>          
          <SurveyOption question={{label: currentQuestion.answer1, value:'v1', qNumber: 1}} setAnswered={setAnswered} isAnswered={isAnswered} setAnswer={setAnswer} />
          <SurveyOption question={{label: currentQuestion.answer2, value:'v2', qNumber: 2}} setAnswered={setAnswered} isAnswered={isAnswered} setAnswer={setAnswer} />
          <br />
          {isAnswered? <SubmitButton  index={index} setIndex={setIndex} setAnswered={setAnswered}  result={result} setResult={setResult} currAnswer={currAnswer}  currentQuestion={currentQuestion} uid={uid} mode={mode}/>:<span></span>}                
        </div>   
      </figure></>
    );
  }
  else if(mode=='final' && answerArr.length>index){
    return(
      <figure className="md:flex p-8 md:p-0   dark:text-stone-950 bg-slate-50 bg-opacity-50 rounded-3xl">  
        <div className="pt-6 md:p-8 md:text-left space-y-4 font-sans">               
          <div className='2xl font-medium'><b>{currentQuestion.label}</b></div>
          <div className='text-center font-sans text-xl'>
            <br />
          </div>                    
          <SurveyOption question={{label: currentQuestion.answer1, value:'v1', qNumber: 1}} setAnswered={setAnswered} isAnswered={isAnswered} setAnswer={setAnswer} />
          <SurveyOption question={{label: currentQuestion.answer2, value:'v2', qNumber: 2}} setAnswered={setAnswered} isAnswered={isAnswered} setAnswer={setAnswer} />
          <SurveyOption question={{label: currentQuestion.answer3, value:'v3', qNumber: 3}} setAnswered={setAnswered} isAnswered={isAnswered} setAnswer={setAnswer} />
          <SurveyOption question={{label: currentQuestion.answer4, value:'v4', qNumber: 4}} setAnswered={setAnswered} isAnswered={isAnswered} setAnswer={setAnswer} />
          <br />
          {isAnswered? <SubmitButton index={index} setIndex={setIndex} setAnswered={setAnswered}  result={result} setResult={setResult} currAnswer={currAnswer}  currentQuestion={currentQuestion} uid={uid} mode={mode}/>:<span></span>}                
        </div>   
      </figure> 
    );
  }
  else if(mode=='path'){
    return(
      <figure className="md:flex p-8 md:p-0   dark:text-stone-950 text-xl bg-slate-50 bg-opacity-50 rounded-3xl">  
        <div className="pt-6 md:p-8 md:text-left space-y-4 font-sans">               
          <div className='2xl font-medium'><b>{currentQuestion.label}</b></div>
          <div className='text-center font-sans text-xl'>
            <br />
          </div>      
          <SurveyOption question={{label: currentQuestion.answer1, value:'v1', qNumber: 1}} setAnswered={setAnswered} isAnswered={isAnswered} setAnswer={setAnswer} />
          <SurveyOption question={{label: currentQuestion.answer2, value:'v2', qNumber: 2}} setAnswered={setAnswered} isAnswered={isAnswered} setAnswer={setAnswer} />
          <SurveyOption question={{label: currentQuestion.answer3, value:'v3', qNumber: 3}} setAnswered={setAnswered} isAnswered={isAnswered} setAnswer={setAnswer} />

          {isAnswered? <SubmitButton index={index} setIndex={setIndex} setAnswered={setAnswered}  result={result} setResult={setResult}  currentQuestion={currentQuestion} currAnswer={currAnswer} uid={uid} mode={mode}/>:<span></span>}                
        </div>   
      </figure> 
    );
  }

}


function generateRandomCode() {
  const chars = 'ABCDEFGHJKLMNPQRSTUVWXYZ23456789';
  let code = '';

  // Генерируем 4 символов или цифр
  for (let i = 0; i < 4; i++) {
    const randomIndex = Math.floor(Math.random() * chars.length);
    code += chars[randomIndex];
  }

  return code;
}


export default App;


